import React from 'react'

import { GridContainer, GridRowGroupOne } from '@/components/Grid'
import { SuperDisplay } from '@/components/Typography'
import { ContentSuperDisplayBlockProps } from '@/interfaces/index'
import { HTMLWrapper } from '@/utils/helpers'

export const ContentSuperDisplayBlock = ({
  title,
  titleHtmlElement,
}: ContentSuperDisplayBlockProps) => (
  <section>
    <GridContainer>
      <GridRowGroupOne>
        <SuperDisplay>
          <HTMLWrapper wrapperElement={titleHtmlElement}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </HTMLWrapper>
        </SuperDisplay>
      </GridRowGroupOne>
    </GridContainer>
  </section>
)
