import React from 'react'

import { ContentfulRichTextContent } from '@/components/cms/__ContentfulRichTextContent'
import { GridContainer } from '@/components/Grid'
import { StaticSpacer } from '@/components/Spacing'
import { StaggeredHeadline } from '@/components/StaggeredHeadline'
import { BodyText } from '@/components/Typography'
import { ContentStaggeredHeadlineTextBlockProps } from '@/interfaces/index'

export const ContentStaggeredHeadlineTextBlock = ({
  textBlock,
  isReversed,
  isHyphenated,
  headlineHtmlElement,
  headingLine1,
  headingLine2,
}: ContentStaggeredHeadlineTextBlockProps) => (
  <section>
    <StaggeredHeadline
      htmlWrapper={headlineHtmlElement}
      headline={[headingLine1, headingLine2]}
      hyphenate={isHyphenated}
      reversed={isReversed}
    />

    <StaticSpacer size="xs" />

    <GridContainer>
      <div className="col-span-full max-w-[600px]">
        <BodyText>
          <ContentfulRichTextContent richText={textBlock} />
        </BodyText>
      </div>
    </GridContainer>
  </section>
)
