import { motion } from 'framer-motion'
import Link from 'next/link'
import React from 'react'

import { Button } from '@/components/Buttons'
import { GridContainer } from '@/components/Grid'
import { ResponsiveSpacer } from '@/components/Spacing'
import { SummaryCard } from '@/components/SummaryCard'
import { BaseText } from '@/components/Typography'
import { ComponentOpenRolesGroupProps } from '@/interfaces/index'
import {
  animationFadeInOut,
  animationFlyInOut,
  HTMLWrapper,
} from '@/utils/helpers'

export const ComponentOpenRolesGroup = ({
  subheading = '',
  subheadingHtmlElement = 'p',
  openRolesCollection,
  showSeeAllOpenRolesCta = true,
}: ComponentOpenRolesGroupProps) => (
  <section className="relative bg-white border text-almostBlack z-1">
    <ResponsiveSpacer size="md" />

    <GridContainer>
      <div className="col-span-full lg:col-span-10">
        <BaseText>
          <HTMLWrapper wrapperElement={subheadingHtmlElement}>
            <span
              className="lowercase text-almostBlack"
              dangerouslySetInnerHTML={{ __html: subheading }}
            />
          </HTMLWrapper>
        </BaseText>
      </div>
    </GridContainer>

    <ResponsiveSpacer size="sm" />

    <GridContainer noMargin>
      {openRolesCollection.items.map((item, i) => (
        <div key={`ShiftLabCaseStudy_${i}`} className="__shift-lab-case-study">
          {i % 2 == 1 && <ResponsiveSpacer size="xl" />}
          <motion.span
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
          >
            <SummaryCard
              title={item.displayTitle}
              ctaText={item.ctaSummaryLink}
              linkUrl={`/careers/${item.slug}`}
              summary={item.jobSummary}
            />
          </motion.span>

          <ResponsiveSpacer size="lg" />
        </div>
      ))}
    </GridContainer>

    {showSeeAllOpenRolesCta && (
      <>
        <ResponsiveSpacer size="lg" />

        <GridContainer>
          <div className="col-span-full">
            <motion.div
              initial="hide"
              whileInView="show"
              variants={animationFlyInOut({ direction: 'flyInFromLeft' })}
            >
              <Button>
                <Link href="/careers">
                  <a className="__shift-lab-button-link">See all open roles</a>
                </Link>
              </Button>
            </motion.div>
          </div>
        </GridContainer>

        <ResponsiveSpacer size="md" />
      </>
    )}
  </section>
)
