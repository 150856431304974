import { motion, useScroll, useTransform } from 'framer-motion'
import Link from 'next/link'
import React, { useRef } from 'react'

import { GridContainer } from '@/components/Grid'
import { IconX } from '@/components/Icons'
import { ResponsiveSpacer } from '@/components/Spacing'
import { BaseText, H2Display } from '@/components/Typography'
import { ComponentBlogRollProps } from '@/interfaces/index'
import { animationFadeInOut, animationRotate } from '@/utils/helpers'

export const ComponentBlogRoll = ({
  postsCollection,
}: ComponentBlogRollProps) => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start end', 'end center'],
  })
  const parallax = useTransform(scrollYProgress, [0, 1], [-300, 100])

  return (
    <section className="relative z-0 bg-white text-almostBlack">
      <ResponsiveSpacer size="md" />

      <GridContainer>
        <div className="col-span-5">
          <BaseText>
            <h2 className="lowercase">Expertise + thinking</h2>
          </BaseText>
        </div>
      </GridContainer>

      <ResponsiveSpacer size="xs" />

      <GridContainer>
        {postsCollection.items.map((item, i) => (
          <div
            className="pr-4 col-span-full lg:col-span-4"
            key={`BlogPost_${i}`}
          >
            <H2Display>
              <h3>
                <motion.span
                  initial="hide"
                  whileInView="show"
                  variants={animationFadeInOut()}
                >
                  <Link href={`/thinking/${item.slug}`}>
                    <a className="underline hover:text-yellow">
                      {item.pageTitle}
                    </a>
                  </Link>
                </motion.span>
              </h3>
            </H2Display>

            <ResponsiveSpacer size="md" />
          </div>
        ))}
      </GridContainer>

      <ResponsiveSpacer size="xs" />

      <GridContainer>
        <div className="max-w-[48%] min-w-[46px] lg:min-w-[56px]">
          <motion.div
            initial="hide"
            whileInView="show"
            variants={animationRotate()}
            style={{
              y: useTransform(parallax, (v) => -v * 2),
            }}
          >
            <IconX color="almostBlack" alt="" />
          </motion.div>
        </div>
      </GridContainer>
      <ResponsiveSpacer size="lg" />
    </section>
  )
}
