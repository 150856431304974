import { motion, useScroll, useTransform } from 'framer-motion'
import Image from 'next/image'
import React, { useRef } from 'react'

import { GridContainer } from '@/components/Grid'
import { ResponsiveSpacer } from '@/components/Spacing'
import { ComponentImageWallProps } from '@/interfaces/index'
import {
  animationFadeInOut,
  animationRotate,
  contentfulImageLoader,
  HTMLWrapper,
} from '@/utils/helpers'

import { IconX } from '../Icons'
import { BaseText, BodyText, H2Display } from '../Typography'
import { ContentfulRichTextContent } from './__ContentfulRichTextContent'

const getMaxWidth = (contentMaxWidth: string) => {
  switch (contentMaxWidth) {
    case '860px':
      return 'max-w-[860px]'
      break
    case '960px':
      return 'max-w-[960px]'
      break
    case '1060px':
      return 'max-w-[1060px]'
      break
    case '1160px':
      return 'max-w-[1160px]'
      break
    case '1260px':
      return 'max-w-[1260px]'
      break
    case '1360px':
      return 'max-w-[1360px]'
      break
    case '1460px':
      return 'max-w-[1460px]'
      break
  }
}

export const ComponentImageWall = ({
  subheading = '',
  subheadingHtmlElement = 'p',
  headline = '',
  headlineHtmlElement = 'p',
  textBlock,
  imagesCollection,
  contentMaxWidth,
}: ComponentImageWallProps) => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start end', 'end center'],
  })
  const parallax = useTransform(scrollYProgress, [0, 1], [-16, 32])
  const parallaxIcon = useTransform(scrollYProgress, [0, 1], [-500, 100])

  return (
    <section className="relative bg-white text-almostBlack z-1">
      <GridContainer>
        <div className="col-span-full">
          <ResponsiveSpacer size="md" />

          {subheading && (
            <BaseText>
              <HTMLWrapper wrapperElement={subheadingHtmlElement}>
                <span
                  className="lowercase"
                  dangerouslySetInnerHTML={{ __html: subheading }}
                />
              </HTMLWrapper>
              <ResponsiveSpacer size="xs" />
            </BaseText>
          )}

          <motion.div
            className={`${getMaxWidth(contentMaxWidth)}`}
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
          >
            {headline && (
              <H2Display>
                <HTMLWrapper wrapperElement={headlineHtmlElement}>
                  <span dangerouslySetInnerHTML={{ __html: headline }} />
                </HTMLWrapper>
              </H2Display>
            )}
          </motion.div>
        </div>
      </GridContainer>

      <motion.div
        initial="hide"
        whileInView="show"
        variants={animationFadeInOut()}
        style={{
          y: useTransform(parallax, (v) => v * 2),
        }}
      >
        <GridContainer>
          {textBlock && (
            <div className="col-span-full xl:col-span-4">
              <BodyText>
                <ResponsiveSpacer size="sm" />
                <ContentfulRichTextContent richText={textBlock} />
              </BodyText>

              <ResponsiveSpacer size="md" />
            </div>
          )}
        </GridContainer>
      </motion.div>

      <motion.div
        initial="hide"
        whileInView="show"
        exit="hide"
        variants={animationFadeInOut()}
      >
        <GridContainer customStyles="absolute">
          <div className="max-w-[48%] min-w-[46px] lg:min-w-[56px] hidden lg:block">
            <motion.div
              initial="hide"
              whileInView="show"
              variants={animationRotate()}
              style={{
                y: useTransform(parallaxIcon, (v) => -v * 2),
              }}
            >
              <IconX color="almostBlack" alt="" />
            </motion.div>
          </div>
        </GridContainer>

        <GridContainer>
          <div className="col-span-full lg:col-span-13 xl:col-span-121">
            <div className="flex flex-wrap items-center justify-center">
              <div className="min-w-[4.5%] flex-initial hidden lg:block" />
              {imagesCollection.items.map((item, i) => (
                <div
                  className="min-w-[25%] lg:min-w-[16%] flex-initial"
                  key={`ShiftLabImageWallItem_${i}`}
                >
                  <Image
                    loader={() =>
                      contentfulImageLoader({
                        src: item.url,
                        width: item.width,
                        quality: 94,
                        fit: 'scale',
                      })
                    }
                    alt="Image showing a product designed or developed by Shift Lab for ..."
                    src={item.url}
                    layout="responsive"
                    height={item.height}
                    width={item.width}
                  />
                </div>
              ))}
            </div>
          </div>
        </GridContainer>
      </motion.div>

      <ResponsiveSpacer size="xl" />
    </section>
  )
}
