import React from 'react'

import { ContentfulRichTextContent } from '@/components/cms/__ContentfulRichTextContent'
import { GridContainer } from '@/components/Grid'
import { ResponsiveSpacer } from '@/components/Spacing'
import { BodyText, H3Display } from '@/components/Typography'
import { ContentRichTextAreaProps } from '@/interfaces/index'
import { HTMLWrapper } from '@/utils/helpers'

const getMaxWidth = (contentMaxWidth: string) => {
  switch (contentMaxWidth) {
    case '660px':
      return 'max-w-[660px]'
      break
    case '760px':
      return 'max-w-[760px]'
      break
    case '860px':
      return 'max-w-[860px]'
      break
    case '960px':
      return 'max-w-[960px]'
      break
    case '1060px':
      return 'max-w-[1060px]'
      break
    case '1160px':
      return 'max-w-[1160px]'
      break
  }
}

export const ContentRichTextArea = ({
  headline = '',
  headlineHtmlElement,
  textBlock,
  contentMaxWidth,
}: ContentRichTextAreaProps) => (
  <section>
    <GridContainer>
      {headline && (
        <div className="font-bold col-span-full">
          <H3Display>
            <HTMLWrapper wrapperElement={headlineHtmlElement}>
              <span
                className="font-bold"
                dangerouslySetInnerHTML={{ __html: headline }}
              />
            </HTMLWrapper>
          </H3Display>
          <hr className="mt-2 w-[64px] h-[6px] bg-yellow border-0" />
          <ResponsiveSpacer size="sm" />
        </div>
      )}

      <div className={`col-span-full ${getMaxWidth(contentMaxWidth)}`}>
        <BodyText>
          <div className="__shift-lab-rich-text-block">
            <ContentfulRichTextContent richText={textBlock} />
          </div>
        </BodyText>
      </div>
    </GridContainer>
  </section>
)
