import Lottie from 'lottie-react'
import Link from 'next/link'
import React, { useState } from 'react'

import { GridContainer } from '@/components/Grid'
import { IconClose } from '@/components/Icons'
import { IconMobileMenuOpen } from '@/components/Icons'
import { ShiftLabMark } from '@/components/Logos'
import { ResponsiveSpacer, StaticSpacer } from '@/components/Spacing'
import { menuItems } from '@/content/default-content'
import shiftLabAnimatedLogoBlackBg from '@/content/shift-lab-animated-logo-black-bg.json'
import shiftLabAnimatedLogoWhiteBg from '@/content/shift-lab-animated-logo-white-bg.json'
import { NavButtonProps } from '@/interfaces/index'

export function Navigation({ useLightTheme = false }): JSX.Element {
  const [isNavVisible, toggleNavVisibility] = useState<boolean>(false)

  const NavButton = ({ children }: NavButtonProps) => (
    <button
      className="absolute p-2 top-6 right-4 md:right-6 md:w-[48px] lg:hidden w-[42px]"
      onClick={() => toggleNavVisibility(!isNavVisible)}
    >
      {children}
    </button>
  )

  return (
    <header
      role="banner"
      className={useLightTheme ? 'bg-white' : 'bg-almostBlack'}
    >
      <StaticSpacer size="sm" />

      <nav className="col-span-full" aria-label="Main website navigation">
        <NavButton>
          <span className="sr-only">Open mobile navigation</span>
          <IconMobileMenuOpen
            alt="Icon - Close mobile menu"
            color={useLightTheme ? 'almostBlack' : 'white'}
          />
        </NavButton>

        <GridContainer>
          <div className="col-span-full lg:col-span-3">
            <Link href="/">
              <a>
                <div className="w-[112px] lg:w-[134px]">
                  <Lottie
                    loop={false}
                    animationData={
                      useLightTheme
                        ? shiftLabAnimatedLogoWhiteBg
                        : shiftLabAnimatedLogoBlackBg
                    }
                  />
                </div>
              </a>
            </Link>
          </div>

          <div className="hidden col-span-10 lg:flex">
            <ul className="relative lowercase flex space-x-9 top-[5px]">
              {menuItems.map((item, i) => (
                <li
                  key={`MenuItem_${i}`}
                  className="text-xs md:text-sm place-self-end"
                >
                  <Link href={item.href}>
                    <a
                      className={`${
                        useLightTheme
                          ? 'text-almostBlack hover:border-almostBlack hover:text-almostBlack'
                          : 'text-white hover:border-white hover:text-white'
                      } hover:border-b-2 no-underline`}
                    >
                      {item.text}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </GridContainer>
      </nav>

      <ResponsiveSpacer size="lg" />

      {isNavVisible && (
        <nav
          className="fixed top-0 z-50 w-screen h-screen bg-white col-span-full"
          aria-label="Mobile website navigation"
        >
          <StaticSpacer size="sm" />

          <NavButton>
            <span className="sr-only">Close mobile navigation</span>
            <IconClose alt="Icon - Mobile menu open" color="almostBlack" />
          </NavButton>

          <GridContainer>
            <div className="mb-12 col-span-full">
              <Link href="/">
                <a>
                  <div className="w-[44px] xl:w-[50px]">
                    <ShiftLabMark
                      color={useLightTheme ? 'almostBlack' : 'white'}
                    />
                  </div>
                </a>
              </Link>
            </div>

            <div className="col-span-full">
              <ul>
                {menuItems.map((item, i) => (
                  <li key={`MenuItemMobile_${i}`}>
                    <Link href={item.href}>
                      <button
                        className="mb-2 font-bold text-left underline lowercase __shift-lab-typography-display-h1 text-almostBlack text-h2Lg"
                        onClick={() => toggleNavVisibility(!isNavVisible)}
                      >
                        {item.text}
                      </button>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </GridContainer>

          <ResponsiveSpacer size="xl" />
        </nav>
      )}
    </header>
  )
}
