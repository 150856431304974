import React from 'react'

import {
  ContentfulImageLoaderProps,
  HTMLWrapperProps,
  imgixLoaderProps,
} from '@/interfaces/index'

import * as Tailwind from '../tailwind.config'

export const imgixLoader = ({
  src,
  width,
  quality,
  format,
}: imgixLoaderProps) => {
  return `https://shiftlab.imgix.net/${src}?w=${width}&q=${quality || 75}&fm=${
    format || 'webp'
  }`
}

export const contentfulImageLoader = ({
  src,
  width,
  quality,
  fit,
  format,
}: ContentfulImageLoaderProps) => {
  return `${src}?w=${width}&fit=${fit}&q=${quality || 90}&fm=${
    format || 'webp'
  }`
}

export const currentYear = new Date().getFullYear()

export const getThemeColor = (color: string) => {
  return Tailwind.theme.colors[color]
}

export const getFullPath = (pageSlug: string[]) => {
  let slug = ''
  pageSlug.map((item) => (slug = slug + item + '/'))
  return slug.slice(0, -1)
}

export const HTMLWrapper = ({
  wrapperElement,
  children,
  classes,
}: HTMLWrapperProps) => {
  let element = <div className={classes}>{children}</div>

  switch (wrapperElement) {
    case 'h1':
      element = <h1 className={classes}>{children}</h1>
      break
    case 'h2':
      element = <h2 className={classes}>{children}</h2>
      break
    case 'h3':
      element = <h3 className={classes}>{children}</h3>
      break
    case 'h4':
      element = <h4 className={classes}>{children}</h4>
      break
    case 'h5':
      element = <h5 className={classes}>{children}</h5>
      break
    case 'h6':
      element = <h6 className={classes}>{children}</h6>
      break
    case 'ol':
      element = <ol className={classes}>{children}</ol>
      break
    case 'ul':
      element = <ul className={classes}>{children}</ul>
      break
    case 'span':
      element = <span className={classes}>{children}</span>
      break
    case 'section':
      element = <section className={classes}>{children}</section>
      break
    case 'p':
      element = <p className={classes}>{children}</p>
      break
  }

  return element
}

export const animationFadeInOut = () => {
  return {
    hide: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        delay: 0.25,
        duration: 1.5,
        ease: 'linear',
        type: 'spring',
      },
    },
  }
}

export const animationFlyInOut = ({ direction }: { direction: string }) => {
  return {
    hide: {
      opacity: 0,
      x: direction === 'flyInFromRight' ? 300 : -300,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.75,
        duration: 0.25,
        ease: 'linear',
        type: 'spring',
      },
    },
  }
}

export const animationRotate = () => {
  return {
    hide: {
      rotate: -45,
    },
    show: {
      rotate: 0,
      transition: {
        delay: 0.1,
        duration: 0.25,
        ease: 'linear',
        type: 'spring',
      },
    },
  }
}
