import { ArbitrarySpacerProps, SpacerProps } from '@/interfaces/index'

const appDebugMode = process.env.DEBUG_DESIGN_SPACING === 'true'

export const ResponsiveSpacer = ({ size, debugMode = false }: SpacerProps) => {
  let spacingClass = ''

  const debugStyles = (appDebugMode || debugMode) && 'bg-debugSpacing'

  switch (size) {
    case 'xs':
      spacingClass = '__shift-lab-spacing-responsive-xs'
      break
    case 'sm':
      spacingClass = '__shift-lab-spacing-responsive-sm'
      break
    case 'md':
      spacingClass = '__shift-lab-spacing-responsive-md'
      break
    case 'lg':
      spacingClass = '__shift-lab-spacing-responsive-lg'
      break
    case 'xl':
      spacingClass = '__shift-lab-spacing-responsive-xl'
      break
    case '2xl':
      spacingClass = '__shift-lab-spacing-responsive-2xl'
      break
    case '3xl':
      spacingClass = '__shift-lab-spacing-responsive-3xl'
      break
  }

  return <div className={`${spacingClass} ${debugStyles}`} />
}

export const StaticSpacer = ({ size, debugMode = false }: SpacerProps) => {
  let spacingClass = ''

  const debugStyles = (appDebugMode || debugMode) && 'bg-debugSpacing'

  switch (size) {
    case 'xs':
      spacingClass = '__shift-lab-spacing-static-xs'
      break
    case 'sm':
      spacingClass = '__shift-lab-spacing-static-sm'
      break
    case 'md':
      spacingClass = '__shift-lab-spacing-static-md'
      break
    case 'lg':
      spacingClass = '__shift-lab-spacing-static-lg'
      break
    case 'xl':
      spacingClass = '__shift-lab-spacing-static-xl'
      break
    case '2xl':
      spacingClass = '__shift-lab-spacing-static-2xl'
      break
    case '3xl':
      spacingClass = '__shift-lab-spacing-static-3xl'
      break
  }

  return <div className={`${spacingClass} ${debugStyles}`} />
}

export const ArbitrarySpacer = ({
  classes,
  debugMode = false,
}: ArbitrarySpacerProps) => {
  const debugStyles = (appDebugMode || debugMode) && 'bg-debugSpacing'

  return <div className={`${classes} ${debugStyles}`} />
}
