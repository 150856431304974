import React from 'react'

import { ComponentBlogRoll } from '@/components/cms/ComponentBlogRoll'
import { ComponentCallToAction } from '@/components/cms/ComponentCallToAction'
import { ComponentCaseStudyGroup } from '@/components/cms/ComponentCaseStudyGroup'
import { ComponentForms } from '@/components/cms/ComponentForms'
import { ComponentIcons } from '@/components/cms/ComponentIcons'
import { ComponentImageWall } from '@/components/cms/ComponentImageWall'
import { ComponentIntroBlock } from '@/components/cms/ComponentIntroBlock'
import { ComponentOpenRolesGroup } from '@/components/cms/ComponentOpenRolesGroup'
import { ComponentSpacers } from '@/components/cms/ComponentSpacers'
import { ContentCaseStudies } from '@/components/cms/ContentCaseStudies'
import { ContentHeroTagline } from '@/components/cms/ContentHeroTagline'
import { ContentRichTextArea } from '@/components/cms/ContentRichTextArea'
import { ContentStaggeredHeadlineTextBlock } from '@/components/cms/ContentStaggeredHeadlineTextBlock'
import { ContentSuperDisplayBlock } from '@/components/cms/ContentSuperDisplayBlock'
import { ContentTextBlockWithHeadline } from '@/components/cms/ContentTextBlockWithHeadline'
import { ContentTwoColumnTextBlockWithHeadline } from '@/components/cms/ContentTwoColumnTextBlockWithHeadline'
import { SummaryCard } from '@/components/SummaryCard'
import { MapPageContentProps } from '@/interfaces/index'

export const MapPageContent = ({ pageContent }: MapPageContentProps) =>
  pageContent.map((item: any, i: number) => {
    switch (item.__typename) {
      case 'ComponentSpacers':
        return (
          <ComponentSpacers
            key={`PageContent_ComponentSpacers_${i}`}
            {...item}
          />
        )
        break
      case 'ComponentIcons':
        return (
          <ComponentIcons key={`PageContent_ComponentIcons_${i}`} {...item} />
        )
        break
      case 'ComponentForms':
        return (
          <ComponentForms
            key={`PageContent_ComponentForms_${i}`}
            id={`PageContent_ComponentForms_${i}`}
            {...item}
          />
        )
        break
      case 'ComponentCallToAction':
        return (
          <ComponentCallToAction
            key={`PageContent_ComponentCallToAction_${i}`}
            {...item}
          />
        )
        break
      case 'ComponentCaseStudyGroup':
        return (
          <ComponentCaseStudyGroup
            key={`PageContent_ComponentCaseStudyGroup_${i}`}
            {...item}
          />
        )
        break
      case 'ComponentOpenRolesGroup':
        return (
          <ComponentOpenRolesGroup
            key={`PageContent_ComponentOpenRolesGroup_${i}`}
            {...item}
          />
        )
        break
      case 'ComponentBlogRoll':
        return (
          <ComponentBlogRoll
            key={`PageContent_ComponentBlogRoll_${i}`}
            {...item}
          />
        )
        break
      case 'ComponentImageWall':
        return (
          <ComponentImageWall
            key={`PageContent_ComponentImageWall_${i}`}
            {...item}
          />
        )
        break
      case 'ComponentIntroBlock':
        return (
          <ComponentIntroBlock
            key={`PageContent_ComponentIntroBlock_${i}`}
            {...item}
          />
        )
        break
      case 'ContentHeroTaglines':
        return (
          <ContentHeroTagline
            key={`PageContent_ContentHeroTaglines_${i}`}
            {...item}
          />
        )
        break
      case 'ContentStaggeredHeadlineTextBlock':
        return (
          <ContentStaggeredHeadlineTextBlock
            key={`PageContent_ContentStaggeredHeadlineTextBlock_${i}`}
            {...item}
          />
        )
        break
      case 'ContentTextBlockWithHeadline':
        return (
          <ContentTextBlockWithHeadline
            key={`PageContent_ContentTextBlockWithHeadline_${i}`}
            {...item}
          />
        )
        break
      case 'ContentTwoColumnTextBlockWithHeadline':
        return (
          <ContentTwoColumnTextBlockWithHeadline
            key={`PageContent_ContentTwoColumnTextBlockWithHeadline_${i}`}
            {...item}
          />
        )
        break
      case 'ContentCaseStudies':
        return (
          <ContentCaseStudies
            key={`PageContent_ContentCaseStudies_${i}`}
            {...item}
          />
        )
        break
      case 'ContentSuperDisplayBlock':
        return (
          <ContentSuperDisplayBlock
            key={`PageContent_ContentSuperDisplayBlock_${i}`}
            {...item}
          />
        )
        break
      case 'ContentRichTextArea':
        return (
          <ContentRichTextArea
            key={`PageContent_ContentRichTextArea_${i}`}
            {...item}
          />
        )
        break
      case 'Careers':
        return (
          <SummaryCard
            title={item.displayTitle}
            ctaText={item.ctaSummaryLink}
            linkUrl={`/careers/${item.slug}`}
            summary={item.jobSummary}
          />
        )
        break
      default:
        return <p>{item.__typename}</p>
        break
    }
  })
