import React from 'react'

import {
  GridContainerProps,
  GridRowComponentProps,
  GridRowProps,
} from '@/interfaces/index'
import { HTMLWrapper } from '@/utils/helpers'

const appDebugMode = process.env.DEBUG_DESIGN_GRID_ROWS === 'true'

export const gridColumns = {
  mobile: 'grid-cols-5',
  desktop: 'md:grid-cols-13',
}

export const gridGutter = {
  mobile: 'gap-x-[.75rem]',
  desktop: 'md:gap-x-[.75rem]',
}

export const gridMargin = {
  mobile: 'mx-[1rem]',
  desktop: 'md:mx-[1.5rem]',
}

const baseGridClasses = `max-w-[2000px] grid ${gridColumns.mobile} ${gridColumns.desktop} ${gridGutter.mobile} ${gridGutter.desktop}`

export const GridContainer = ({
  children,
  noMargin = false,
  customStyles = '',
  wrapperElement = '',
}: GridContainerProps) => {
  return (
    <HTMLWrapper
      classes={`${customStyles} ${baseGridClasses} ${
        !noMargin && gridMargin.mobile
      } ${!noMargin && gridMargin.desktop}`}
      wrapperElement={wrapperElement}
    >
      {children}
    </HTMLWrapper>
  )
}

export const GridRow = ({
  children,
  customStyles = '',
  wrapperElement = 'div',
  gridRules = '',
  debugMode = false,
}: GridRowComponentProps) => {
  const debugGridRowStyles = (appDebugMode || debugMode) && 'bg-debugGrid'

  return (
    <HTMLWrapper
      classes={`${gridRules} ${customStyles} ${debugGridRowStyles}`}
      wrapperElement={wrapperElement}
    >
      {children}
    </HTMLWrapper>
  )
}

export const GridRowGroupOne = ({
  children,
  customStyles = '',
  wrapperElement = 'div',
  debugMode = false,
}: GridRowProps) => (
  <GridRow
    gridRules="!col-start-1 md:col-start-1 lg:col-start-1 col-span-full"
    customStyles={customStyles}
    wrapperElement={wrapperElement}
    debugMode={debugMode}
  >
    {children}
  </GridRow>
)

export const GridRowGroupTwo = ({
  children,
  customStyles = '',
  wrapperElement = 'div',
  debugMode = false,
}: GridRowProps) => (
  <GridRow
    gridRules="!col-start-1 col-span-full md:!col-start-2"
    customStyles={customStyles}
    wrapperElement={wrapperElement}
    debugMode={debugMode}
  >
    {children}
  </GridRow>
)

export const GridRowGroupThree = ({
  children,
  customStyles = '',
  wrapperElement = 'div',
  debugMode = false,
}: GridRowProps) => (
  <GridRow
    gridRules="!col-start-1 col-span-full md:!col-start-4 lg:!col-start-4"
    customStyles={customStyles}
    wrapperElement={wrapperElement}
    debugMode={debugMode}
  >
    {children}
  </GridRow>
)

export const GridRowGroupFour = ({
  children,
  customStyles = '',
  wrapperElement = 'div',
  debugMode = false,
}: GridRowProps) => (
  <GridRow
    gridRules="col-span-full !col-start-1 md:!col-start-5 lg:!col-start-5"
    customStyles={customStyles}
    wrapperElement={wrapperElement}
    debugMode={debugMode}
  >
    {children}
  </GridRow>
)

export const GridRowGroupFive = ({
  children,
  customStyles = '',
  wrapperElement = 'div',
  debugMode = false,
}: GridRowProps) => (
  <GridRow
    gridRules="col-span-full !col-start-2 md:!col-start-6 lg:!col-start-6"
    customStyles={customStyles}
    wrapperElement={wrapperElement}
    debugMode={debugMode}
  >
    {children}
  </GridRow>
)

export const GridRowGroupSix = ({
  children,
  customStyles = '',
  wrapperElement = 'div',
  debugMode = false,
}: GridRowProps) => (
  <GridRow
    gridRules="col-span-full !col-start-2 md:!col-start-6 lg:!col-start-9"
    customStyles={customStyles}
    wrapperElement={wrapperElement}
    debugMode={debugMode}
  >
    {children}
  </GridRow>
)
