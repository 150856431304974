import { motion } from 'framer-motion'
import { useLottie, useLottieInteractivity } from 'lottie-react'
import Link from 'next/link'
import React from 'react'

import { Button } from '@/components/Buttons'
import { ContentfulRichTextContent } from '@/components/cms/__ContentfulRichTextContent'
import { GridContainer } from '@/components/Grid'
import { ResponsiveSpacer } from '@/components/Spacing'
import { BaseText, BodyText, H2Display } from '@/components/Typography'
import { ComponentCallToActionProps } from '@/interfaces/index'
import {
  animationFadeInOut,
  animationFlyInOut,
  HTMLWrapper,
} from '@/utils/helpers'

export const ComponentCallToAction = (
  ctaContent: ComponentCallToActionProps
) => {
  const style = {
    border: 0,
  }

  const options = {
    animationData: ctaContent.animationJson,
  }

  const ContactAnimation = () => {
    const lottieObj = useLottie(options, style)
    const Animation = useLottieInteractivity({
      lottieObj,
      mode: 'scroll',
      actions: [
        {
          visibility: [0.01, 0.9],
          type: 'play',
          frames: [0, 420],
        },
      ],
    })

    return Animation
  }

  return (
    <section className="relative z-1" id="contact-cta">
      <GridContainer>
        <div className="col-span-full lg:col-span-6 bg-almostBlack">
          <ResponsiveSpacer size="md" />

          {ctaContent.subheading && (
            <BaseText>
              <HTMLWrapper wrapperElement={ctaContent.subheadingHtmlElement}>
                <span
                  className="lowercase text-yellow"
                  dangerouslySetInnerHTML={{ __html: ctaContent.subheading }}
                />
              </HTMLWrapper>
              <ResponsiveSpacer size="xs" />
            </BaseText>
          )}

          <motion.div
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
          >
            {ctaContent.headline && (
              <H2Display>
                <HTMLWrapper wrapperElement={ctaContent.headlineHtmlElement}>
                  <span
                    dangerouslySetInnerHTML={{ __html: ctaContent.headline }}
                  />
                </HTMLWrapper>
              </H2Display>
            )}
          </motion.div>

          <ResponsiveSpacer size="sm" />

          <motion.div
            initial="hide"
            whileInView="show"
            variants={animationFlyInOut({ direction: 'flyInFromLeft' })}
          >
            {ctaContent.textBlock && (
              <div className="col-span-full xl:col-span-4 xl:max-w-[640px]">
                <BodyText>
                  <ResponsiveSpacer size="sm" />
                  <ContentfulRichTextContent richText={ctaContent.textBlock} />
                </BodyText>
              </div>
            )}

            <ResponsiveSpacer size="md" />

            <Button>
              <Link href={ctaContent.buttonUrl}>
                <a className="__shift-lab-button-link">
                  <span>{ctaContent.buttonText}</span>
                </a>
              </Link>
            </Button>
          </motion.div>

          <ResponsiveSpacer size="lg" />
        </div>
        <div className="col-span-full lg:col-span-7 lg:col-start-7">
          <motion.div
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
          >
            <ContactAnimation />
          </motion.div>
        </div>
      </GridContainer>
    </section>
  )
}
