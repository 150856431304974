import { H4Display } from '@/components/Typography'
import { ButtonProps } from '@/interfaces/index'

export const Button = ({ children, noBorder = false }: ButtonProps) => (
  <div className="relative inline-block mb-2 after:transition-all after:absolute after:left-4 after:z-0 after:top-2 after:w-full after:h-full after:border-b-8 after:border-r-8 after:border-yellow after:opacity-0 hover:after:opacity-100">
    <div
      className={`relative z-10 mb-2 transition-all border-yellow text-yellow bg-almostBlack ${
        !noBorder && 'border-2'
      }`}
    >
      <H4Display bold>{children}</H4Display>
    </div>
  </div>
)
