import Link from 'next/link'

import { GridContainer } from '@/components/Grid'
import { ResponsiveSpacer, StaticSpacer } from '@/components/Spacing'
import { SummaryCardProps } from '@/interfaces/index'

import { BodyText, H2Display } from './Typography'

export const SummaryCard = ({
  title,
  ctaText,
  linkUrl,
  summary,
}: SummaryCardProps) => {
  return (
    <GridContainer>
      <div className="col-span-full max-w-[800px]">
        <H2Display>
          <p>{title}</p>
          <StaticSpacer size="xs" />
        </H2Display>
        <BodyText>
          <div dangerouslySetInnerHTML={{ __html: summary }} />

          <ResponsiveSpacer size="sm" />

          <Link href={linkUrl}>
            <a className="__shift-lab-typography-text-inline-link">{ctaText}</a>
          </Link>
        </BodyText>
      </div>
    </GridContainer>
  )
}
