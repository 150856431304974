import Head from 'next/head'
import Script from 'next/script'
import React, { ReactNode } from 'react'

import { Footer } from '@/components/Footer'
import { Navigation } from '@/components/Navigation'

type LayoutProps = {
  children: ReactNode
  title?: string
  description?: string
  url: string
  imageCTA?: string
  shouldBeIndexed?: boolean
  useLightTheme?: boolean
}

const OG_IMAGE_URL = process.env.NEXT_PUBLIC_OG_IMAGE_URL
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID
const HUBSPOT_ID = process.env.NEXT_PUBLIC_HUBSPOT_ID

const Layout = ({
  children,
  title = '',
  url,
  imageCTA = '',
  description = '',
  shouldBeIndexed = true,
  useLightTheme = false,
}: LayoutProps) => (
  <div>
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1, width=device-width" />
      {!shouldBeIndexed && <meta name="robots" content="noindex,nofollow" />}
      <link rel="icon" type="image/x-icon" href="/favicon.svg" />
      <link rel="canonical" href={`https://shiftlab.co${url}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@shiftlabny" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={`${OG_IMAGE_URL}?cta=${imageCTA}&w=1200&h=630`}
      />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta
        name="og:image"
        content={`${OG_IMAGE_URL}?cta=${imageCTA}&w=1200&h=630`}
      />
      <meta name="og:title" content={title} />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: 'Shift Lab',
            image: `${OG_IMAGE_URL}?cta=Let’s%20talk.&w=1200&h=630`,
            '@id': '',
            url: 'https://shiftlab.co',
            telephone: '',
            address: {
              '@type': 'PostalAddress',
              streetAddress: '290 5th Ave Fl 4',
              addressLocality: 'New York',
              addressRegion: 'NY',
              postalCode: '10001',
              addressCountry: 'US',
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: 40.7463124,
              longitude: -73.9864727,
            },
            sameAs: [
              'https://www.facebook.com/shiftlab.co/',
              'https://twitter.com/shiftlabny/',
              'https://www.linkedin.com/company/shiftlabny/',
              'https://www.instagram.com/shiftlab.co/',
            ],
          }),
        }}
      />
    </Head>

    {/* Google Tag Manager */}
    <Script id="google-analytics" strategy="afterInteractive">
      {`
        (function(w,d,s,l,i) {
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          }
        )(window, document, 'script', 'dataLayer', '${GTM_ID}');
      `}
    </Script>

    {/* Hubspot Embed Code */}
    {HUBSPOT_ID && (
      <Script
        strategy="afterInteractive"
        id="hs-script-loader"
        src={`//js-na1.hs-scripts.com/${HUBSPOT_ID}.js`}
      />
    )}

    <Navigation useLightTheme={useLightTheme} />
    <div role="main">{children}</div>
    <Footer />
  </div>
)

export default Layout
