import React from 'react'

import { LogoProps } from '@/interfaces/index'
import { getThemeColor } from '@/utils/helpers'

export const ShiftLabLogo = ({
  color = 'almostBlack',
  customStyles = '',
}: LogoProps) => (
  <div className={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 138 42"
      preserveAspectRatio="xMinYMid"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Shift Lab - Logo</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.35 28.1886C25.539 30.1676 26.386 31.8896 27.89 33.1256C29.392 34.3616 31.361 34.9806 33.796 34.9806C35.774 34.9806 37.382 34.5716 38.619 33.7526C39.855 32.9356 40.474 31.7846 40.474 30.3006C40.474 29.2346 40.121 28.3976 39.418 27.7896C38.713 27.1806 37.734 26.7046 36.479 26.3626C35.223 26.0206 33.302 25.6016 30.714 25.1066C26.719 24.3466 23.58 23.1286 21.297 21.4536C19.015 19.7806 17.873 17.1166 17.873 13.4646C17.873 11.1436 18.501 9.06062 19.757 7.21462C21.012 5.36962 22.762 3.92362 25.007 2.87662C27.252 1.83162 29.763 1.30762 32.541 1.30762C35.546 1.30762 38.228 1.86962 40.588 2.99062C42.947 4.11362 44.811 5.65462 46.181 7.61462C47.551 9.57462 48.254 11.7716 48.293 14.2056H39.903C39.712 12.4936 38.952 11.0956 37.62 10.0106C36.288 8.92662 34.595 8.38462 32.541 8.38462C30.714 8.38462 29.24 8.76562 28.118 9.52562C26.995 10.2876 26.434 11.3906 26.434 12.8356C26.434 13.8636 26.786 14.6816 27.49 15.2906C28.193 15.8986 29.192 16.3746 30.486 16.7166C31.779 17.0596 33.682 17.4586 36.193 17.9156C38.819 18.4106 41.054 19.0476 42.9 19.8276C44.745 20.6076 46.238 21.7676 47.38 23.3086C48.521 24.8496 49.092 26.8766 49.092 29.3876C49.092 31.7846 48.416 33.9446 47.066 35.8646C45.715 37.7866 43.87 39.2896 41.53 40.3736C39.19 41.4586 36.573 42.0006 33.682 42.0006C30.41 42.0006 27.518 41.4006 25.007 40.2026C22.496 39.0036 20.536 37.3586 19.129 35.2656C17.721 33.1736 16.997 30.8136 16.96 28.1886H25.35Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.617 33.125C14.114 31.89 13.267 30.167 13.077 28.188H4.68799C4.72499 30.814 5.44799 33.173 6.85599 35.265C8.26299 37.358 10.223 39.004 12.735 40.203C14.04 40.825 15.45 41.282 16.961 41.582V33.998C16.479 33.752 16.029 33.464 15.617 33.125Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2176 15.2906C14.5126 14.6816 14.1616 13.8636 14.1616 12.8356C14.1616 11.3906 14.7226 10.2876 15.8446 9.52561C16.1816 9.29761 16.5606 9.11561 16.9606 8.95561V1.59961C15.4646 1.85361 14.0486 2.26561 12.7346 2.87661C10.4886 3.92361 8.73959 5.36961 7.48459 7.21461C6.22859 9.06061 5.60059 11.1436 5.60059 13.4646C5.60059 17.1166 6.74159 19.7806 9.02459 21.4536C11.0176 22.9156 13.6636 24.0286 16.9606 24.7936V16.2956C16.2606 16.0146 15.6716 15.6826 15.2176 15.2906Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.168 35.265C2.87 36.309 3.71 37.242 4.688 38.064V28.188H0C0.037 30.814 0.76 33.173 2.168 35.265Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79609 7.21431C1.54009 9.06031 0.913086 11.1433 0.913086 13.4643C0.913086 17.1163 2.05409 19.7803 4.33709 21.4533C4.44909 21.5363 4.57109 21.6133 4.68809 21.6933V5.09131C3.97409 5.73231 3.33009 6.42931 2.79609 7.21431Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.6166 15.1559C78.8806 17.2679 80.0126 20.7789 80.0126 25.6879V41.6719H71.9646V26.0879C71.9646 21.2169 69.9846 18.7809 66.0276 18.7809C63.9726 18.7809 62.3646 19.4759 61.2036 20.8639C60.0426 22.2539 59.4626 24.1659 59.4626 26.6019V41.6719H51.4136V1.71289H59.4626V15.4689C60.4906 14.3659 61.6786 13.5099 63.0306 12.9009C64.3806 12.2929 66.0276 11.9879 67.9686 11.9879C71.4696 11.9879 74.3516 13.0439 76.6166 15.1559Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.8984 41.672H90.9474V12.33H82.8984V41.672ZM90.2914 1.313C91.1844 2.189 91.6324 3.292 91.6324 4.624C91.6324 5.957 91.1844 7.07 90.2914 7.963C89.3964 8.858 88.2834 9.305 86.9514 9.305C85.6194 9.305 84.4974 8.858 83.5834 7.963C82.6704 7.07 82.2134 5.957 82.2134 4.624C82.2134 3.292 82.6704 2.189 83.5834 1.313C84.4974 0.438 85.6194 0 86.9514 0C88.2834 0 89.3964 0.438 90.2914 1.313Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.6323 12.3304V10.0474C98.6323 7.3454 99.3743 5.2804 100.859 3.8534C102.342 2.4264 104.569 1.7124 107.537 1.7124H114.045V8.6764H108.907C108.146 8.6764 107.585 8.8484 107.223 9.1904C106.861 9.5334 106.681 10.0854 106.681 10.8464V12.3304H114.045V19.2384H106.681V41.6724H98.6323V19.2384H93.7803V12.3304H98.6323Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.262 19.2382H116.41V12.3302H121.262V4.05322H129.311V12.3302H135.991V19.2382H129.311V32.0822C129.311 33.0342 129.501 33.7092 129.882 34.1082C130.262 34.5082 130.928 34.7072 131.88 34.7072H136.675V41.6722H129.825C126.894 41.6722 124.735 40.9592 123.346 39.5312C121.956 38.1042 121.262 35.9452 121.262 33.0522V19.2382Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </div>
)

export const ShiftLabMark = ({
  color = 'almostBlack',
  customStyles = '',
}: LogoProps) => (
  <div className={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 42"
      preserveAspectRatio="xMinYMid"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Shift Lab - Logo Mark</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3509 27.1886C25.5399 29.1676 26.3869 30.8896 27.8909 32.1256C29.3929 33.3616 31.3619 33.9806 33.7969 33.9806C35.7749 33.9806 37.3829 33.5716 38.6199 32.7526C39.8559 31.9356 40.4749 30.7846 40.4749 29.3006C40.4749 28.2346 40.1219 27.3976 39.4189 26.7896C38.7139 26.1806 37.7349 25.7046 36.4799 25.3626C35.2239 25.0206 33.3029 24.6016 30.7149 24.1066C26.7199 23.3466 23.5809 22.1286 21.2979 20.4536C19.0159 18.7806 17.8739 16.1166 17.8739 12.4646C17.8739 10.1436 18.5019 8.06062 19.7579 6.21462C21.0129 4.36962 22.7629 2.92362 25.0079 1.87662C27.2529 0.831617 29.7639 0.307617 32.5419 0.307617C35.5469 0.307617 38.2289 0.869617 40.5889 1.99062C42.9479 3.11362 44.8119 4.65462 46.1819 6.61462C47.5519 8.57462 48.2549 10.7716 48.2939 13.2056H39.9039C39.7129 11.4936 38.9529 10.0956 37.6209 9.01062C36.2889 7.92662 34.5959 7.38462 32.5419 7.38462C30.7149 7.38462 29.2409 7.76562 28.1189 8.52562C26.9959 9.28762 26.4349 10.3906 26.4349 11.8356C26.4349 12.8636 26.7869 13.6816 27.4909 14.2906C28.1939 14.8986 29.1929 15.3746 30.4869 15.7166C31.7799 16.0596 33.6829 16.4586 36.1939 16.9156C38.8199 17.4106 41.0549 18.0476 42.9009 18.8276C44.7459 19.6076 46.2389 20.7676 47.3809 22.3086C48.5219 23.8496 49.0929 25.8766 49.0929 28.3876C49.0929 30.7846 48.4169 32.9446 47.0669 34.8646C45.7159 36.7866 43.8709 38.2896 41.5309 39.3736C39.1909 40.4586 36.5739 41.0006 33.6829 41.0006C30.4109 41.0006 27.5189 40.4006 25.0079 39.2026C22.4969 38.0036 20.5369 36.3586 19.1299 34.2656C17.7219 32.1736 16.9979 29.8136 16.9609 27.1886H25.3509Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6165 32.125C14.1135 30.89 13.2665 29.167 13.0765 27.188H4.6875C4.7245 29.814 5.4475 32.173 6.8555 34.265C8.2625 36.358 10.2225 38.004 12.7345 39.203C14.0395 39.825 15.4495 40.282 16.9605 40.582V32.998C16.4785 32.752 16.0285 32.464 15.6165 32.125Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2186 14.2906C14.5136 13.6816 14.1626 12.8636 14.1626 11.8356C14.1626 10.3906 14.7236 9.28761 15.8456 8.52561C16.1826 8.29761 16.5616 8.11561 16.9616 7.95561V0.599609C15.4656 0.853609 14.0496 1.26561 12.7356 1.87661C10.4896 2.92361 8.74056 4.36961 7.48556 6.21461C6.22956 8.06061 5.60156 10.1436 5.60156 12.4646C5.60156 16.1166 6.74256 18.7806 9.02556 20.4536C11.0186 21.9156 13.6646 23.0286 16.9616 23.7936V15.2956C16.2616 15.0146 15.6726 14.6826 15.2186 14.2906Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.168 34.2655C2.87 35.3095 3.71 36.2425 4.688 37.0645V27.1885H0C0.037 29.8145 0.76 32.1735 2.168 34.2655Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79706 6.2148C1.54106 8.0608 0.914062 10.1438 0.914062 12.4648C0.914062 16.1168 2.05506 18.7808 4.33806 20.4538C4.45006 20.5368 4.57206 20.6138 4.68906 20.6938V4.0918C3.97506 4.7328 3.33106 5.4298 2.79706 6.2148Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </div>
)
