import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import Image from 'next/image'

import { contentfulImageLoader } from '@/utils/helpers'

function renderOptions(links: any) {
  //
  //
  // Read more about this approach here:
  // https://www.contentful.com/blog/2021/04/14/rendering-linked-assets-entries-in-contentful/
  //
  //

  const assetMap = new Map()

  if (links) {
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset)
    }
  }

  return {
    renderNode: {
      [INLINES.HYPERLINK]: (node: any) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {node.content[0].value}
          </a>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const asset = assetMap.get(node.data.target.sys.id)

        return (
          <div className="embedded-asset">
            <Image
              loader={() =>
                contentfulImageLoader({
                  src: asset.url,
                  width: asset.width,
                  quality: 90,
                  fit: 'thumb',
                })
              }
              src={asset.url}
              alt={asset.title}
              layout="responsive"
              width={asset.width}
              height={asset.height}
            />
          </div>
        )
      },
    },
  }
}

export function ContentfulRichTextContent({ richText }: any): JSX.Element {
  return (
    <>
      {documentToReactComponents(richText.json, renderOptions(richText.links))}
    </>
  )
}
