// eslint-disable-next-line @typescript-eslint/no-var-requires
const tokens = require('./utils/tokens.json')

module.exports = {
  content: [
    './pages/**/*.{ts,tsx}',
    './components/**/*.{ts,tsx}',
    './stories/**/*.{ts,tsx}',
  ],
  theme: {
    colors: {
      almostBlack: tokens.global.colors.almostBlack.value,
      white: tokens.global.colors.white.value,
      yellow: tokens.global.colors.yellow.value,
      black: tokens.global.colors.black.value,
      transparent: 'transparent',
      debugSpacing: tokens.global.colors.debugSpacing.value,
      debugGrid: tokens.global.colors.debugGrid.value,
    },
    fontFamily: {
      sans: [tokens.global.fontFamily.sans.value],
    },
    fontWeight: {
      normal: tokens.global.fontWeight.normal.value,
      medium: tokens.global.fontWeight.medium.value,
      bold: tokens.global.fontWeight.bold.value,
    },
    fontSize: {
      super: [
        '13.75rem',
        {
          lineHeight: 0.95,
          letterSpacing: -0.5,
        },
      ],
      '3xl': [
        '5.75rem',
        {
          lineHeight: 1,
          letterSpacing: 0.5,
        },
      ],
      '2xl': [
        '3.5rem',
        {
          lineHeight: 1,
          letterSpacing: -0.35,
        },
      ],
      xl: [
        '2.5rem',
        {
          lineHeight: 1,
          letterSpacing: -0.25,
        },
      ],
      lg: [
        '1.4rem',
        {
          lineHeight: 1.15,
          letterSpacing: 0.14,
        },
      ],
      md: [
        '1.125rem',
        {
          lineHeight: 1.2,
          letterSpacing: 0.125,
        },
      ],
      sm: [
        '1rem',
        {
          lineHeight: 1.2,
          letterSpacing: 0.1,
        },
      ],
      xs: [
        '.85rem',
        {
          lineHeight: 1.2,
          letterSpacing: 0.1,
        },
      ],
    },
    extend: {
      spacing: {
        18: '4.5rem',
        30: '7.5rem',
        38: '9.55rem',
        58: '14.55rem',
        76: '19rem',
        98: '29rem',
      },

      gridTemplateColumns: {
        13: 'repeat(13, minmax(0, 1fr))',
      },
    },
  },
  variant: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/forms')({
      strategy: 'class',
    }),
  ],
}
