import Image from 'next/image'

import { Button } from '@/components/Buttons'
import { GridContainer } from '@/components/Grid'
import { gridGutter } from '@/components/Grid'
import { ResponsiveSpacer, StaticSpacer } from '@/components/Spacing'
import { H4Display } from '@/components/Typography'
import {
  contactItems,
  socialItems,
  tradeOrgItems,
} from '@/content/default-content'
import { currentYear } from '@/utils/helpers'

export const Footer = () => (
  <footer className="bg-black">
    <ResponsiveSpacer size="2xl" />
    <section>
      <GridContainer>
        <div className="col-span-full md:col-span-6">
          <div
            className={`grid grid-cols-6 ${gridGutter.mobile} ${gridGutter.desktop}`}
          >
            <div className="lowercase opacity-50 col-span-full xl:col-span-2 xl:opacity-100">
              <H4Display>
                <p>Contact</p>
              </H4Display>
              <StaticSpacer size="md" />
            </div>
            <div className="col-span-full m:col-span-4">
              <H4Display>
                {contactItems.map((item, i) => (
                  <div key={`ContactItem_${i}`}>
                    <p>{item.label}</p>
                    <p className="__shift-lab-typography-text-inline-link">
                      <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </p>
                    <ResponsiveSpacer size="xl" />
                  </div>
                ))}
              </H4Display>
            </div>
          </div>
        </div>
        <div className="col-span-full lg:col-span-6 lg:col-start-8">
          <div
            className={`grid grid-cols-6 ${gridGutter.mobile} ${gridGutter.desktop}`}
          >
            <div className="lowercase opacity-50 col-span-full xl:col-span-2 xl:opacity-100">
              <H4Display>
                <p>Headquarters</p>
              </H4Display>
              <StaticSpacer size="md" />
            </div>
            <div className="col-span-full m:col-span-4">
              <H4Display>
                <p>New York</p>
                <div>
                  <a
                    className="__shift-lab-typography-text-inline-link"
                    target="_blank"
                    href="https://goo.gl/maps/4hDwNe9a3nA3TQhd9"
                    rel="noreferrer"
                  >
                    <p>290 5th Ave Fl 4</p>
                    <p>New York, NY 10001</p>
                  </a>
                  <p>Between 30th &amp; 31st</p>
                </div>
              </H4Display>
              <ResponsiveSpacer size="xl" />
            </div>
            <div className="lowercase opacity-50 col-span-full xl:col-span-2 xl:opacity-100">
              <H4Display>
                <p>Social</p>
              </H4Display>
              <StaticSpacer size="md" />
            </div>
            <div className="col-span-full m:col-span-4">
              <H4Display>
                {socialItems.map((item, i) => (
                  <p key={`SocialItem_${i}`}>
                    <a target="_blank" href={item.href} rel="noreferrer">
                      <span className="__shift-lab-typography-text-inline-link">
                        {item.text}
                      </span>
                    </a>
                  </p>
                ))}
              </H4Display>
              <ResponsiveSpacer size="xl" />
            </div>
          </div>
        </div>
      </GridContainer>
    </section>

    <GridContainer>
      <div className="order-1 lg:order-0 col-span-full lg:col-span-7">
        <p className="text-base md:text-body">
          &copy; Shift Lab {currentYear}. All rights reserved.
        </p>
        <StaticSpacer size="lg" />
      </div>
      <div className="lg:order-1 order-0 col-span-full md:col-span-6">
        <div
          className={`grid grid-cols-6 ${gridGutter.mobile} ${gridGutter.desktop}`}
        >
          <div className="col-span-full xl:col-span-2">
            <p className="text-base opacity-50 md:text-body xl:opacity-100">
              Proud member of
            </p>
            <StaticSpacer size="xs" />
          </div>
          <div className="col-span-full lg:col-span-4">
            <div className="flex space-x-12">
              {tradeOrgItems.map((item, i) => (
                <Button key={`TradeOrgItem_${i}`} noBorder>
                  <a target="_blank" href={item.href} rel="noreferrer">
                    <Image
                      alt={item.text}
                      src={item.imagePath}
                      width={item.imageSize.width}
                      height={item.imageSize.height}
                    />
                  </a>
                </Button>
              ))}
            </div>
            <StaticSpacer size="md" />
          </div>
        </div>
      </div>
    </GridContainer>
  </footer>
)
