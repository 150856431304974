import React from 'react'

import {
  IconAnimatedProps,
  IconParentProps,
  IconProps,
} from '@/interfaces/index'
import { getThemeColor } from '@/utils/helpers'

export const Icon = ({ customStyles, children }: IconParentProps) => (
  <div className={customStyles}>{children}</div>
)

export const IconCursor = ({
  animated = false,
  alt,
  color = 'white',
  customStyles = '',
}: IconAnimatedProps) => (
  <Icon customStyles={customStyles}>
    <title>{alt ? alt : 'Cursor icon'}</title>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 108 67"
      height="100%"
      fill="none"
      preserveAspectRatio="xMinYMid"
      className={`__shift-lab-icon-cursor ${
        animated && '__shift-lab-svg-animate-blink'
      }`}
    >
      <path
        className={`${animated && '__shift-lab-svg-animate-blink'}`}
        d="M60.34,64.73h47.47v-20H60.34Z"
        fill={getThemeColor(color)}
      />
      <polygon
        fill={getThemeColor(color)}
        points="47.84 33.57 47.7 33.43 47.7 33.43 14.27 0 0.14 14.13 19.43 33.43 0 52.87 14.13 67 47.84 33.57"
      />
    </svg>
  </Icon>
)

export const IconClose = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 42 42"
      fill="none"
      preserveAspectRatio="xMinYMid"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt ? alt : 'Close icon'}</title>
      <path
        d="M33.6982 5L36.5267 7.82843L6.82818 37.5269L3.99976 34.6985L33.6982 5Z"
        fill={getThemeColor(color)}
      />
      <path
        d="M4 7.82861L6.82843 5.00019L36.5269 34.6987L33.6985 37.5271L4 7.82861Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconX = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 222 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'X icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2097 0L0.46284 46.834L64.2852 110.764L0 175.166L46.7469 222L158.25 111.228L47.2097 0Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.79 0L221.537 46.834L157.715 110.764L222 175.166L175.253 222L63.75 111.228L174.79 0Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconMobileMenuOpen = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Open mobile menu icon'}</title>
      <path d="M38 0H42V42H38V0Z" fill={getThemeColor(color)} />
      <rect x="12" width="4" height="100%" fill={getThemeColor(color)} />
      <rect width="4" height="100%" fill={getThemeColor(color)} />
    </svg>
  </Icon>
)

export const IconArrow = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Arrow icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0559 0L26.9431 0.110322L0 36.6448L11.3913 47.8897L26.9431 40.4902L42.6077 48L54 36.7551L27.0559 0Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconDesktopComputer = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 48 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Desktop computer icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 31.999H39.999V0H8V31.999Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 55.999H48V39.999H0V55.999Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconEnvelope = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Envelope icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 48H60V0H0V48ZM8 14.399V6H52V14.399L30 29.411L8 14.399Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconMobileDevice = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Mobile device icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 60H40V0H0V60ZM16 51.9999H24V43.9997H16V51.9999Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconMonitor = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Desktop monitor icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 40H60V0H0V40Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 56H49.001V48H11V56Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconPhone = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 55.02 55.02"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Phone icon'}</title>
      <path
        fill={getThemeColor(color)}
        d="M52.96,38.05l-12.8-2.97-4.23,4.23c-8.92-4.35-16.14-11.63-20.4-20.6l4.26-4.26-2.81-12.4L14.86,0H2.13C1.3,.85,.83,1.33,0,2.18,1.12,30.81,24.21,53.9,52.84,55.02c.85-.83,1.33-1.3,2.18-2.13v-12.72l-2.06-2.11Z"
      />
    </svg>
  </Icon>
)

export const IcoMonitor = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Monitor icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 40H60V0H0V40Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 56H49.001V48H11V56Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconPlay = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 55 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Play icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V69L55 34.4995L0 0Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconPlus = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Plus icon'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000976562 34H60.001V26H0.000976562V34Z"
        fill={getThemeColor(color)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.001 60V0H26.001L26.001 60H34.001Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)

export const IconWatch = ({
  alt,
  color = 'white',
  customStyles = '',
}: IconProps) => (
  <Icon customStyles={customStyles}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 72"
      preserveAspectRatio="xMinYMid"
    >
      <title>{alt ? alt : 'Watch icon'}</title>
      <path d="M6,8h32V0H6V8Z" fill={getThemeColor(color)} />
      <path d="M6,72h32v-8H6v8Z" fill={getThemeColor(color)} />
      <path
        d="M0,14V58H44V14H0Zm32,27h-14V23h8v10h6v8Z"
        fill={getThemeColor(color)}
      />
    </svg>
  </Icon>
)
