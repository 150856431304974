import React from 'react'

import { ResponsiveSpacer, StaticSpacer } from '@/components/Spacing'
import { ComponentSpacersProps } from '@/interfaces/index'

export const ComponentSpacers = ({
  size,
  responsiveOrStatic,
}: ComponentSpacersProps) =>
  responsiveOrStatic === 'responsive' ? (
    <ResponsiveSpacer size={size} />
  ) : (
    <StaticSpacer size={size} />
  )
