import {
  BlogPostsSummaryProps,
  ContactItemsProps,
  MenuItemsProps,
  TradeOrgItemsProps,
} from '../interfaces'

export const menuItems: MenuItemsProps = [
  {
    text: 'Development',
    href: '/development',
  },
  {
    text: 'Product + UX Design',
    href: '/product-ux-design',
  },
  {
    text: 'Expertise',
    href: '/expertise',
  },
  {
    text: 'Culture',
    href: '/culture',
  },
  {
    text: 'Work',
    href: '/work',
  },
  {
    text: 'Careers',
    href: '/careers',
  },
]

export const socialItems: MenuItemsProps = [
  {
    text: 'Instagram',
    href: 'https://www.instagram.com/shiftlab.co/',
  },
  {
    text: 'Facebook',
    href: 'https://www.facebook.com/shiftlab.co/',
  },
  {
    text: 'LinkedIn',
    href: 'https://www.linkedin.com/company/shiftlabny/',
  },
  {
    text: 'X',
    href: 'https://x.com/shiftlabny/',
  },
]

export const workMenuItems: MenuItemsProps = [
  {
    text: 'Ecommerce',
    href: '/work',
  },
  {
    text: 'Content Platforms',
    href: '/work',
  },
  {
    text: 'Healthcare',
    href: '/work',
  },
  {
    text: 'Fintech',
    href: '/work',
  },
  {
    text: 'Established Startups',
    href: '/work',
  },
  {
    text: 'Lifestyle + Travel',
    href: '/work',
  },
]

export const contactItems: ContactItemsProps = [
  {
    label: 'Careers',
    text: 'careers@shiftlab.co',
  },
  {
    label: 'General Inquiries',
    text: 'info@shiftlab.co',
  },
  {
    label: 'New Business',
    text: 'newbiz@shiftlab.co<br />+ 1 646 360 0498',
  },
]

export const tradeOrgItems: TradeOrgItemsProps = [
  {
    text: 'Shift Lab is a proud member of SoDA, The Society of Digital Agencies',
    href: 'https://sodaspeaks.com',
    imagePath: '/images/soda.png',
    imageSize: {
      height: 120,
      width: 94,
    },
  },
  {
    text: 'Shift Lab is a proud member of The Bureau of Digital',
    href: 'https://bureauofdigital.com/',
    imagePath: '/images/bureau-of-digital.png',
    imageSize: {
      height: 120,
      width: 220,
    },
  },
]

export const blogPosts: BlogPostsSummaryProps = [
  {
    title: 'Using ChatGPT as a Development Tool',
    slug: '/thinking/shift-lab-using-chatgpt-as-a-development-tool',
  },
  {
    title:
      'How High-Value Technology Projects Can Increase Lifetime Account Value',
    slug: '/thinking/shift-lab-how-high-value-technology-projects-can-increase-lifetime-account-value',
  },
  {
    title: 'Top 8 Tech Development Pitfalls for Design Agencies ',
    slug: '/thinking/shift-lab-top-8-tech-development-pitfalls-for-design-agencies',
  },
]
