import React from 'react'

import { TypographyProps } from '@/interfaces/index'

export const SuperDisplay = ({
  children,
  bold = false,
  italic = false,
}: TypographyProps) => (
  <div
    className={`lg:-ml-4 __shift-lab-typography-display-super ${
      bold ? 'font-bold' : 'font-normal'
    } ${italic ? 'italic' : 'not-italic'}`}
  >
    {children}
  </div>
)

export const H1Display = ({
  children,
  bold = false,
  italic = false,
}: TypographyProps) => (
  <div
    className={`md:-ml-2 __shift-lab-typography-display-h1 ${
      bold ? 'font-bold' : 'font-normal'
    } ${italic ? 'italic' : 'not-italic'}`}
  >
    {children}
  </div>
)

export const H2Display = ({
  children,
  bold = false,
  italic = false,
}: TypographyProps) => (
  <div
    className={`__shift-lab-typography-display-h2 ${
      bold ? 'font-bold' : 'font-normal'
    } ${italic ? 'italic' : 'not-italic'}`}
  >
    {children}
  </div>
)

export const H3Display = ({
  children,
  bold = false,
  italic = false,
}: TypographyProps) => (
  <div
    className={`__shift-lab-typography-display-h3 ${
      bold ? 'font-bold' : 'font-normal'
    } ${italic ? 'italic' : 'not-italic'}`}
  >
    {children}
  </div>
)

export const H4Display = ({
  children,
  bold = false,
  italic = false,
}: TypographyProps) => (
  <div
    className={`__shift-lab-typography-display-h4 ${
      bold ? 'font-bold' : 'font-normal'
    } ${italic ? 'italic' : 'not-italic'}`}
  >
    {children}
  </div>
)

export const BodyText = ({
  children,
  bold = false,
  italic = false,
}: TypographyProps) => (
  <div
    className={`__shift-lab-typography-text-body ${
      bold ? 'font-bold' : 'font-normal'
    } ${italic ? 'italic' : 'not-italic'}`}
  >
    {children}
  </div>
)

export const BaseText = ({
  children,
  bold = false,
  italic = false,
}: TypographyProps) => (
  <div
    className={`__shift-lab-typography-text-base ${bold ? 'font-bold' : ''} ${
      italic ? 'italic' : 'not-italic'
    }`}
  >
    {children}
  </div>
)
