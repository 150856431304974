import React from 'react'

import { H1Display } from '@/components/Typography'
import { ContentHeroTaglineProps } from '@/interfaces/index'
import { HTMLWrapper } from '@/utils/helpers'

import { GridContainer, GridRowGroupOne } from '../Grid'

const getMaxWidth = (contentMaxWidth: string) => {
  switch (contentMaxWidth) {
    case '1060px':
      return 'max-w-[1060px]'
      break
    case '1160px':
      return 'max-w-[1160px]'
      break
    case '1260px':
      return 'max-w-[1260px]'
      break
    case '1360px':
      return 'max-w-[1360px]'
      break
    case '1460px':
      return 'max-w-[1460px]'
      break
    case '1560px':
      return 'max-w-[1560px]'
      break
    case '1660px':
      return 'max-w-[1660px]'
      break
  }
}

export const ContentHeroTagline = ({
  taglineHtmlElement,
  taglineCopy,
  contentMaxWidth,
}: ContentHeroTaglineProps) => (
  <section>
    <GridContainer>
      <GridRowGroupOne customStyles={`${getMaxWidth(contentMaxWidth)}`}>
        <H1Display>
          <HTMLWrapper wrapperElement={taglineHtmlElement}>
            <span dangerouslySetInnerHTML={{ __html: taglineCopy }} />
          </HTMLWrapper>
        </H1Display>
      </GridRowGroupOne>
    </GridContainer>
  </section>
)
