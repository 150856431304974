import React from 'react'

import {
  GridContainer,
  GridRowGroupOne,
  GridRowGroupTwo,
} from '@/components/Grid'
import { H2Display } from '@/components/Typography'
import { StaggeredHeadlineProps } from '@/interfaces/index'
import { HTMLWrapper } from '@/utils/helpers'

export const StaggeredHeadline = ({
  headline,
  reversed,
  hyphenate,
  htmlWrapper,
}: StaggeredHeadlineProps) => (
  <div className="lowercase">
    <H2Display>
      <HTMLWrapper wrapperElement={htmlWrapper}>
        <GridContainer wrapperElement="span">
          {!reversed ? (
            <>
              <GridRowGroupTwo wrapperElement="span">
                {headline[0]}
                {hyphenate && <span className="after:content-['–']" />}
              </GridRowGroupTwo>

              <GridRowGroupOne wrapperElement="span">
                {headline[1]}
              </GridRowGroupOne>
            </>
          ) : (
            <>
              <GridRowGroupOne wrapperElement="span">
                {headline[0]}
                {hyphenate && <span className="after:content-['–']" />}
              </GridRowGroupOne>

              <GridRowGroupTwo wrapperElement="span">
                {headline[1]}
              </GridRowGroupTwo>
            </>
          )}
        </GridContainer>
      </HTMLWrapper>
    </H2Display>
  </div>
)
