import { motion } from 'framer-motion'
import Link from 'next/link'
import React from 'react'

import { GridContainer } from '@/components/Grid'
import { ResponsiveSpacer } from '@/components/Spacing'
import { ComponentCaseStudyGroupProps } from '@/interfaces/index'
import { animationFlyInOut, HTMLWrapper } from '@/utils/helpers'

import { Button } from '../Buttons'
import { BaseText } from '../Typography'
import { ContentCaseStudies } from './ContentCaseStudies'

export const ComponentCaseStudyGroup = ({
  subheading = '',
  subheadingHtmlElement = 'p',
  showSeeAllWorkCta = false,
  caseStudiesCollection,
}: ComponentCaseStudyGroupProps) => (
  <div className="relative z-1">
    <ResponsiveSpacer size="md" />

    <GridContainer>
      <div className="col-span-full lg:col-span-10">
        <BaseText>
          <HTMLWrapper wrapperElement={subheadingHtmlElement}>
            <span
              className="lowercase text-yellow"
              dangerouslySetInnerHTML={{ __html: subheading }}
            />
          </HTMLWrapper>
        </BaseText>
      </div>
    </GridContainer>

    <ResponsiveSpacer size="sm" />

    <GridContainer>
      {caseStudiesCollection.items.map((item, i) => (
        <div key={`ShiftLabCaseStudy_${i}`} className="__shift-lab-case-study">
          {i % 2 == 1 && <ResponsiveSpacer size="2xl" />}
          <ContentCaseStudies {...item} />
        </div>
      ))}
    </GridContainer>

    {showSeeAllWorkCta && (
      <>
        <GridContainer>
          <div className="col-span-full">
            <motion.div
              initial="hide"
              whileInView="show"
              variants={animationFlyInOut({ direction: 'flyInFromLeft' })}
            >
              <Button>
                <Link href="/work">
                  <a className="__shift-lab-button-link">See all work</a>
                </Link>
              </Button>
            </motion.div>
          </div>
        </GridContainer>

        <ResponsiveSpacer size="lg" />
      </>
    )}
  </div>
)
