import { motion, useScroll, useTransform } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useHubspotForm } from 'next-hubspot'
import React, { useRef } from 'react'

import { ContentfulRichTextContent } from '@/components/cms/__ContentfulRichTextContent'
import { GridContainer } from '@/components/Grid'
import { ResponsiveSpacer } from '@/components/Spacing'
import {
  BaseText,
  BodyText,
  H2Display,
  H4Display,
} from '@/components/Typography'
import { contactItems } from '@/content/default-content'
import { ComponentFormsProps } from '@/interfaces/index'
import {
  animationFadeInOut,
  animationRotate,
  HTMLWrapper,
  imgixLoader,
} from '@/utils/helpers'

import { IconX } from '../Icons'

const HUBSPOT_ID = process.env.NEXT_PUBLIC_HUBSPOT_ID

const HubspotForm = (id: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: `${HUBSPOT_ID}`,
    formId: `${id}`,
    target: `#hubspot-general-contact-form-wrapper__key__${id}`,
  })

  return (
    <section className="__shift-lab-embedded-form hubspot-webform">
      <div id={`hubspot-general-contact-form-wrapper__key__${id}`} />
    </section>
  )
}

export const ComponentForms = ({
  subheading = '',
  subheadingHtmlElement,
  headline = '',
  headlineHtmlElement,
  textBlock,
  hubspotFormId,
  showContactInformation = false,
  blogRoll,
}: ComponentFormsProps) => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start end', 'end center'],
  })
  const parallax = useTransform(scrollYProgress, [0, 1], [-200, 100])

  return (
    <div className="relative z-1 hubspot-webform bg-almostBlack">
      <GridContainer>
        <div className="col-span-full lg:col-span-6">
          <div className="">
            <ResponsiveSpacer size="md" />

            {subheading && (
              <BaseText>
                <HTMLWrapper wrapperElement={subheadingHtmlElement}>
                  <span
                    className="lowercase text-yellow"
                    dangerouslySetInnerHTML={{ __html: subheading }}
                  />
                </HTMLWrapper>
                <ResponsiveSpacer size="xs" />
              </BaseText>
            )}

            <motion.div
              initial="hide"
              whileInView="show"
              variants={animationFadeInOut()}
            >
              {headline && (
                <H2Display>
                  <HTMLWrapper wrapperElement={headlineHtmlElement}>
                    <span dangerouslySetInnerHTML={{ __html: headline }} />
                  </HTMLWrapper>
                </H2Display>
              )}
            </motion.div>

            <motion.div
              initial="hide"
              whileInView="show"
              variants={animationFadeInOut()}
            >
              {textBlock && (
                <div className="xl:max-w-[640px]">
                  <BodyText>
                    <ResponsiveSpacer size="sm" />
                    <ContentfulRichTextContent richText={textBlock} />
                  </BodyText>
                </div>
              )}
            </motion.div>

            <ResponsiveSpacer size="sm" />

            <motion.span
              initial="hide"
              whileInView="show"
              variants={animationFadeInOut()}
            >
              {HubspotForm(hubspotFormId)}
            </motion.span>

            <ResponsiveSpacer size="lg" />
          </div>
        </div>

        <div className="col-span-full lg:col-span-5 lg:col-start-8 2xl:col-span-4 2xl:col-start-8">
          {showContactInformation && (
            <>
              <ResponsiveSpacer size="md" />

              <a
                className="__shift-lab-typography-text-inline-link"
                target="_blank"
                href="https://goo.gl/maps/4hDwNe9a3nA3TQhd9"
                rel="noreferrer"
              >
                <Image
                  loader={() =>
                    imgixLoader({
                      src: 'shift-lab-nyc-map.jpg',
                      width: 966,
                      quality: 95,
                      format: 'webp',
                    })
                  }
                  alt="Map of NYC showing Shift Lab office location"
                  src="shift-lab-nyc-map.jpg"
                  layout="responsive"
                  height={453}
                  width={966}
                />
              </a>

              <ResponsiveSpacer size="sm" />

              <H4Display>
                <div>
                  <a
                    className="__shift-lab-typography-text-inline-link"
                    target="_blank"
                    href="https://goo.gl/maps/4hDwNe9a3nA3TQhd9"
                    rel="noreferrer"
                  >
                    <p>290 5th Ave Fl 4</p>
                    <p>New York, NY 10001</p>
                  </a>
                  <p>Between 30th &amp; 31st</p>
                </div>
              </H4Display>

              <ResponsiveSpacer size="md" />

              <H4Display>
                {contactItems.reverse().map((item, i) => (
                  <div key={`ContactItem_${i}`}>
                    <p className="text-yellow">{item.label}</p>
                    <p>
                      <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </p>
                    <ResponsiveSpacer size="md" />
                  </div>
                ))}
              </H4Display>

              <ResponsiveSpacer size="lg" />
            </>
          )}

          {blogRoll && (
            <section>
              <ResponsiveSpacer size="md" />

              <GridContainer noMargin>
                <div className="col-span-full">
                  <BaseText>
                    <h2 className="lowercase text-yellow">
                      Expertise + thinking
                    </h2>
                  </BaseText>
                </div>
              </GridContainer>

              <ResponsiveSpacer size="sm" />

              <GridContainer noMargin>
                {blogRoll.postsCollection.items.map((item, i) => (
                  <div className="col-span-full" key={`BlogPost_${i}`}>
                    <H2Display>
                      <h3 className="">
                        <motion.span
                          initial="hide"
                          whileInView="show"
                          variants={animationFadeInOut()}
                        >
                          <Link href={`/thinking/${item.slug}`}>
                            <a className="underline block hover:text-yellow bg-white text-almostBlack p-[.75rem] pt-[1rem] pb-[1.25rem] hover:bg-black">
                              {item.pageTitle}
                            </a>
                          </Link>
                        </motion.span>
                      </h3>
                    </H2Display>

                    <ResponsiveSpacer size="md" />
                  </div>
                ))}
              </GridContainer>
            </section>
          )}

          <GridContainer noMargin>
            <div className="max-w-[48%] min-w-[46px] lg:min-w-[56px]">
              <motion.div
                initial="hide"
                whileInView="show"
                variants={animationRotate()}
                style={{
                  y: useTransform(parallax, (v) => -v * 2),
                }}
              >
                <IconX color="white" alt="" />
              </motion.div>
            </div>
          </GridContainer>
        </div>
      </GridContainer>
    </div>
  )
}
