import React from 'react'

import { GridContainer } from '@/components/Grid'
import { IconCursor } from '@/components/Icons'
import { ComponentIconsProps } from '@/interfaces/index'

export const ComponentIcons = ({ iconType }: ComponentIconsProps) => (
  <>
    {iconType === 'icon-cursor__animated' && (
      <GridContainer>
        <div className="-ml-2 col-span-full">
          <IconCursor
            alt=""
            animated
            color="white"
            customStyles="p-2 w-[56px] md:w-[76px] lg:w-[112px]"
          />
        </div>
      </GridContainer>
    )}
  </>
)
