import { motion, useScroll, useTransform } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useRef } from 'react'

import { ContentfulRichTextContent } from '@/components/cms/__ContentfulRichTextContent'
import { GridContainer } from '@/components/Grid'
import { ShiftLabMark } from '@/components/Logos'
import { ResponsiveSpacer } from '@/components/Spacing'
import { BaseText, BodyText, H2Display } from '@/components/Typography'
import { ComponentIntroBlockProps } from '@/interfaces/index'
import {
  animationFadeInOut,
  animationFlyInOut,
  contentfulImageLoader,
  HTMLWrapper,
} from '@/utils/helpers'

import { Button } from '../Buttons'

const getMaxWidth = (contentMaxWidth: string) => {
  switch (contentMaxWidth) {
    case '560px':
      return 'max-w-[860px]'
      break
    case '660px':
      return 'max-w-[860px]'
      break
    case '760px':
      return 'max-w-[860px]'
      break
    case '860px':
      return 'max-w-[860px]'
      break
    case '960px':
      return 'max-w-[960px]'
      break
    case '1060px':
      return 'max-w-[1060px]'
      break
  }
}

export const ComponentIntroBlock = ({
  subheading = '',
  subheadingHtmlElement,
  headline = '',
  headlineHtmlElement,
  textBlock,
  contentMaxWidth,
  ctaButtonText,
  ctaButtonUrl = '',
  showLogoParallax,
  parallaxImageLeft,
  parallaxImageRight,
}: ComponentIntroBlockProps) => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start end', 'end start'],
  })
  const parallax = useTransform(scrollYProgress, [0, 1], [-100, 400])

  return (
    <section className="relative z-1">
      <GridContainer>
        <motion.div
          className="h-[460px] lg:h-auto col-span-full lg:col-span-6"
          style={{ y: useTransform(parallax, (v) => -v * 4) }}
        >
          {subheading && (
            <BaseText>
              <HTMLWrapper wrapperElement={subheadingHtmlElement}>
                <span
                  className="lowercase text-yellow"
                  dangerouslySetInnerHTML={{ __html: subheading }}
                />
              </HTMLWrapper>
              <ResponsiveSpacer size="xs" />
            </BaseText>
          )}

          {headline && (
            <H2Display>
              <div className={`${getMaxWidth(contentMaxWidth)}`}>
                <HTMLWrapper wrapperElement={headlineHtmlElement}>
                  <span dangerouslySetInnerHTML={{ __html: headline }} />
                </HTMLWrapper>
                <ResponsiveSpacer size="xs" />
              </div>
            </H2Display>
          )}

          <motion.div
            className="max-w-[660px]"
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
          >
            {textBlock && (
              <div className="col-span-full xl:col-span-4">
                <BodyText>
                  <ResponsiveSpacer size="sm" />
                  <ContentfulRichTextContent richText={textBlock} />
                </BodyText>
              </div>
            )}
          </motion.div>

          <ResponsiveSpacer size="md" />

          <motion.div
            initial="hide"
            whileInView="show"
            variants={animationFlyInOut({ direction: 'flyInFromLeft' })}
          >
            {ctaButtonText && (
              <Button>
                <Link href={ctaButtonUrl}>
                  <a className="__shift-lab-button-link">{ctaButtonText}</a>
                </Link>
              </Button>
            )}
          </motion.div>
        </motion.div>
        <motion.div
          className="hidden col-span-1 col-start-8 lg:block"
          initial="hide"
          whileInView="show"
          variants={animationFadeInOut()}
          style={{ y: useTransform(parallax, (v) => -v * 4) }}
        >
          {parallaxImageLeft && (
            <Image
              loader={() =>
                contentfulImageLoader({
                  src: parallaxImageLeft.url,
                  width: parallaxImageLeft.width,
                  quality: 94,
                  fit: 'scale',
                })
              }
              alt="="
              src={parallaxImageLeft.url}
              layout="responsive"
              height={parallaxImageLeft.height}
              width={parallaxImageLeft.width}
            />
          )}
        </motion.div>

        <motion.div
          className="relative hidden col-span-1 overflow-hidden lg:block"
          initial="hide"
          whileInView="show"
          variants={animationFlyInOut({ direction: 'flyInFromRight' })}
          style={{ y: useTransform(parallax, (v) => v * 2) }}
        >
          {showLogoParallax && (
            <div className="w-[200px] xl:w-[260px] absolute bottom-[15%] xl:-right-[175px] -right-[135px]">
              <ShiftLabMark color="yellow" />
            </div>
          )}
        </motion.div>

        <div className="hidden col-span-3 col-start-10 lg:block">
          <motion.div
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
            style={{ y: useTransform(parallax, (v) => v * 4) }}
          >
            {parallaxImageRight && (
              <Image
                loader={() =>
                  contentfulImageLoader({
                    src: parallaxImageRight.url,
                    width: parallaxImageRight.width,
                    quality: 94,
                    fit: 'scale',
                  })
                }
                alt="="
                src={parallaxImageRight.url}
                layout="responsive"
                height={parallaxImageRight.height}
                width={parallaxImageRight.width}
              />
            )}
          </motion.div>
        </div>
      </GridContainer>
    </section>
  )
}
