import { motion } from 'framer-motion'
import { useLottie, useLottieInteractivity } from 'lottie-react'
import Image from 'next/image'
import React from 'react'

import { ContentfulRichTextContent } from '@/components/cms/__ContentfulRichTextContent'
import { ResponsiveSpacer } from '@/components/Spacing'
import { BodyText, H2Display } from '@/components/Typography'
import { ContentCaseStudyProps } from '@/interfaces/index'
import { animationFadeInOut, contentfulImageLoader } from '@/utils/helpers'

export const ContentCaseStudies = ({
  displayTitle,
  image,
  textBlock,
  animationJson,
}: ContentCaseStudyProps) => {
  const LottieAnimation = () => {
    const lottieObj = useLottie({
      animationData: animationJson,
    })
    const Animation = useLottieInteractivity({
      lottieObj,
      mode: 'scroll',
      actions: [
        {
          visibility: [0.2, 0.75],
          type: 'seek',
          frames: [1, 119],
        },
      ],
    })

    return Animation
  }

  return (
    <>
      <motion.div
        initial="hide"
        whileInView="show"
        variants={animationFadeInOut()}
      >
        {animationJson ? (
          <div>
            <LottieAnimation />
          </div>
        ) : (
          <div className="max-w-[83%]">
            <Image
              loader={() =>
                contentfulImageLoader({
                  src: image.url,
                  width: image.width,
                  quality: 94,
                  fit: 'scale',
                })
              }
              alt={`Image showing a product designed or developed by Shift Lab for ${displayTitle}`}
              src={image.url}
              layout="responsive"
              height={image.height}
              width={image.width}
            />
          </div>
        )}
      </motion.div>

      <ResponsiveSpacer size="md" />

      <H2Display>
        <h4>
          <motion.span
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
          >
            {displayTitle}
          </motion.span>
        </h4>
      </H2Display>

      <ResponsiveSpacer size="xs" />

      <BodyText>
        <div className="max-w-[720px]">
          <motion.span
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
          >
            <div className="__shift-lab-rich-text-block">
              <ContentfulRichTextContent richText={textBlock} />
            </div>
          </motion.span>
        </div>
      </BodyText>

      <ResponsiveSpacer size="md" />
    </>
  )
}
