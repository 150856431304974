import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'

import { ContentfulRichTextContent } from '@/components/cms/__ContentfulRichTextContent'
import { GridContainer } from '@/components/Grid'
import { ResponsiveSpacer } from '@/components/Spacing'
import { BaseText, BodyText, H2Display } from '@/components/Typography'
import { ContentTextBlockWithHeadlineProps } from '@/interfaces/index'
import { animationFadeInOut, HTMLWrapper } from '@/utils/helpers'

const getMaxWidth = (contentMaxWidth: string) => {
  switch (contentMaxWidth) {
    case '860px':
      return 'max-w-[860px]'
      break
    case '960px':
      return 'max-w-[960px]'
      break
    case '1060px':
      return 'max-w-[1060px]'
      break
    case '1160px':
      return 'max-w-[1160px]'
      break
    case '1260px':
      return 'max-w-[1260px]'
      break
    case '1360px':
      return 'max-w-[1360px]'
      break
    case '1460px':
      return 'max-w-[1460px]'
      break
  }
}

const getColors = (backgroundColor: string) => {
  switch (backgroundColor) {
    case 'bg-almostBlack':
      return 'bg-almostBlack text-white'
      break
    case 'bg-white':
      return 'bg-white text-almostBlack'
      break
    case 'bg-yellow':
      return 'bg-yellow text-almostBlack'
      break
  }
}

export const ContentTextBlockWithHeadline = ({
  backgroundColor,
  subheading = '',
  subheadingHtmlElement,
  headline = '',
  headlineHtmlElement,
  textBlock,
  textBlockRightColumn,
  contentMaxWidth,
}: ContentTextBlockWithHeadlineProps) => {
  const target = useRef(null)
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start end', 'end center'],
  })
  const parallax = useTransform(scrollYProgress, [0, 1], [-16, 32])

  return (
    <section className={`z-1 relative ${getColors(backgroundColor)}`}>
      <GridContainer>
        <div className="col-span-full">
          <ResponsiveSpacer size="md" />

          {subheading && (
            <BaseText>
              <HTMLWrapper wrapperElement={subheadingHtmlElement}>
                <span
                  className={`lowercase  ${
                    backgroundColor === 'bg-almostBlack' ? 'text-yellow' : ''
                  }`}
                  dangerouslySetInnerHTML={{ __html: subheading }}
                />
              </HTMLWrapper>
              <ResponsiveSpacer size="xs" />
            </BaseText>
          )}

          <motion.div
            className={`${getMaxWidth(contentMaxWidth)}`}
            initial="hide"
            whileInView="show"
            variants={animationFadeInOut()}
          >
            {headline && (
              <H2Display>
                <HTMLWrapper wrapperElement={headlineHtmlElement}>
                  <span dangerouslySetInnerHTML={{ __html: headline }} />
                </HTMLWrapper>
              </H2Display>
            )}
          </motion.div>
        </div>
      </GridContainer>

      <motion.div
        initial="hide"
        whileInView="show"
        variants={animationFadeInOut()}
        style={{
          y: useTransform(parallax, (v) => v * 2),
        }}
      >
        <GridContainer>
          {textBlock && (
            <div className="col-span-full xl:col-span-4">
              <BodyText>
                <ResponsiveSpacer size="sm" />
                <ContentfulRichTextContent richText={textBlock} />
              </BodyText>
            </div>
          )}

          {textBlockRightColumn && (
            <div className="col-span-full xl:col-span-4 xl:col-start-6">
              <BodyText>
                <ResponsiveSpacer size="sm" />
                <ContentfulRichTextContent richText={textBlockRightColumn} />
                <ResponsiveSpacer size="lg" />
              </BodyText>
            </div>
          )}
        </GridContainer>

        {!textBlockRightColumn && <ResponsiveSpacer size="lg" />}
      </motion.div>
    </section>
  )
}
